.signin_container {
  .signin_panel.loading {
    background: transparent url(/media/export/cms/479-01%20Clinique_LoadingGif_R22.gif) no-repeat scroll center 10%;
  }
  .loading {
    * {
      opacity: 0.8;
      pointer-events: none;
    }
  }
  section.sign-in {
    text-align: center;
    .row {
      text-align: left;
      width: 325px;
      margin: auto;
    }
  }
  input[type='submit'] {
    height: calc(3em - -2px);
  }
}

.checkout-wrapper {
  .sign-in-panel {
    input[type='submit'] {
      font-size: 100%;
    }
    .signin_container {
      form[name='signin_new_mobile'] {
        input[type='submit'] {
          float: right;
          margin-top: -40px !important;
          @include breakpoint($cr19-small) {
            margin-top: -44px !important;
          }
        }
      }
    }
  }
}

#signin,
#checkout_signin {
  .signin_container {
    form {
      label {
        display: inline;
      }
    }
  }
}

#index {
  .signin_container {
    .signin_panel {
      form#signin,
      form#sms_pincode,
      form#checkout_signin {
        input {
          margin-top: auto;
        }
        input[type='text'],
        input[type='password'] {
          height: calc(3em - -2px);
          width: 100%;
        }
      }
    }
  }
  #main {
    #signin {
      .signin_panel {
        input[type='submit'] {
          padding: 0 0;
          height: calc(3em - -2px);
          font-size: 100%;
        }
      }
    }
    .invoice-change-email {
      input[type='submit'] {
        padding: 1em 3em;
        font-size: 1em;
      }
    }
  }
}

.form-item--full {
  input {
    width: 100%;
  }
}

.btn--full {
  width: 100%;
}

.float-right {
  float: right;
}

input[type='submit'].loading,
input[type='button'].loading {
  background: transparent url('/media/export/cms/479-01%20Clinique_LoadingGif_R22.gif') no-repeat scroll center center !important;
  background-size: contain !important;
  text-indent: -999em;
}

.pc_hidden {
  display: none;
  @include breakpoint($cr19-small) {
    display: block;
  }
}

.mobile_hidden {
  @include breakpoint($cr19-small) {
    display: none;
  }
}

.profile-form-container {
  .grayout_dummy_email {
    text-indent: -999px;
  }
}
