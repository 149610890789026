.locator_content {
  width: 100%;
  .locations_form_panel {
    padding: 0 10px;
    .address_section_container {
      .form_element {
        label,
        select {
          width: 100%;
        }
        label {
          color: #231f20;
          font-weight: bold;
        }
        select {
          margin: 10px 0;
          padding: 5px 13px;
        }
      }
    }
  }
  .locations_table_panel {
    padding: 0 10px;
    #store_results {
      .door_row {
        border-bottom: 1px solid #d8d7d7;
        padding: 10px 0 10px 0;
        .store_info {
          .store_name {
            font-weight: bold;
          }
        }
        .store_extra {
          .extra_phone {
            p a {
              background-color: #5dba98;
              border: 0 none;
              color: #ffffff;
              display: inline-block;
              font-size: 0.9em;
              letter-spacing: 0;
              line-height: 1.5;
              padding: 4px 10px 3px;
              text-align: center;
              text-transform: uppercase;
              border-radius: 0;
              height: auto;
              line-height: 23px;
              margin: 3px 0 15px;
              min-height: 34px;
              width: 100%;
              font-size: 18px;
            }
          }
          .extra_address {
            a.directions {
              background-color: #ffffff;
              border: 1px solid #5dba98;
              color: #5dba98;
              margin: 0;
              border-radius: 0;
              height: auto;
              line-height: 23px;
              margin: 3px 0 15px;
              min-height: 34px;
              width: 100%;
              font-size: 18px;
              display: inline-block;
              padding: 4px 10px 3px;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
