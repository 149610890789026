.section-clinicalreality {
  .nav-promo {
    @include start-breakpoint($medium-min) {
      display: none;
    }
  }
  .wo-inline {
    display: none;
  }
  .clinicalreality-container {
    padding: 0;
  }
}

#diagnostics {
  .diagnostics-header {
    .clinical-reality__results-intro {
      display: block;
    }
  }
  .clinical-reality {
    &__results-intro {
      display: none;
    }
  }
}

.clinical-reality__mpp {
  &--carousel {
    bottom: -5px;
  }
}

.clinical-reality {
  &__recommended-products {
    &--container {
      a.product-add-to-bag {
        margin-top: 10px;
      }
    }
  }
}
