@mixin input-field {
  width: 13px;
  height: 13px;
}

@mixin icon-symbol($selector) {
  .icons .icon-heart#{$selector}:before {
    margin-right: 10px;
  }
}

@mixin cart-light-gray {
  border-bottom: 1px solid $cart-light-gray;
}

@mixin footer-icons {
  float: left;
  margin: 0 0 0 5px;
}

@mixin footer-mobile-links {
  display: block;
  padding: 10px 0;
}

@mixin mixn_mpp-btn-holder {
  position: absolute;
  bottom: 10px;
  left: 0px;
}

@mixin mixn_selectskuholder {
  position: absolute;
  bottom: 50px;
  width: 100%;
}

@mixin sticky-button {
  bottom: 0;
  position: fixed;
  height: 50px;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 15px;
  z-index: 1;
}
