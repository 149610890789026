$color_hot_pink_approx: #ef6ea8;
body {
  &.device-mobile {
    .block.block-template-site-logo-responsive-v1 {
      div.site-logo-responsive-v1 {
        padding: 0;
        a img {
          margin: -20px 0 0 0;
        }
      }
    }
    #header {
      border-top: 2px solid #e6e6e6;
      border-bottom: 2px solid #e6e6e6;
      height: auto;
      padding: 0;
      .btn_navigation {
        background: none;
        height: auto;
      }
      li {
        float: left;
        list-style-type: none;
        padding: 15px;
        text-align: center;
        vertical-align: middle;
        width: 25%;
        font-size: 12px;
        border-right: 1px solid #e6e6e6;
        color: $color-black;
        a {
          color: $color-black;
        }
      }
      li.last {
        border-right: none;
      }
    }
    .block-template-search-mobile-v2 {
      float: right;
    }
    .block-template-gnav-cart-v1 {
      float: right;
    }
    #search {
      #search-results {
        .results {
          .result {
            .badge.badge_30 {
              width: 39px;
              line-height: 33px;
              font-size: 7px;
              height: 37px;
              padding-top: 2px;
              top: 4px;
            }
          }
        }
      }
    }
  }
  &.elc-user-state-logged-in {
    .menu-mlid-296ae432-3c2f-11e3-814d-000c29cc69ea {
      display: none;
    }
    .menu-mlid-49cd7850-43c4-11e4-857a-000c29cc69ea {
      display: inline;
    }
  }
  &.elc-user-state-anonymous {
    .menu-mlid-296ae432-3c2f-11e3-814d-000c29cc69ea {
      display: inline;
    }
    .menu-mlid-49cd7850-43c4-11e4-857a-000c29cc69ea {
      display: none;
    }
  }
  ul.spp_view {
    li {
      font-family: $base-font-family;
    }
  }
  .error {
    color: $color_hot_pink_approx;
  }
  div.BVSectionBody {
    #BVFieldRecommendContainerID {
      float: left;
    }
    .BVFieldRecommend .BVFieldLabel {
      float: none;
    }
    #BVFieldContextdatavalueDidYouReceiveAFreeSampleContainerID {
      float: left;
    }
    .BVFieldDidYouReceiveAFreeSample .BVFieldLabel {
      float: none;
    }
  }
  #footer {
    .footer-bottom {
      .block .sns_icons_section {
        @include footer-mobile-links;
        border-top: 1px solid #cccccc;
        border-bottom: 1px solid #cccccc;
      }
      .block .sns_icons_section p {
        display: block;
        text-align: center;
      }
      .block .sns_icons_section p img {
        padding: 10px 0;
      }
      .block .footer_links_section {
        @include footer-mobile-links;
        text-align: center;
      }
      .block .footer_links_section .footer_row {
        @include footer-mobile-links;
      }
      .block .footer_links_section .footer_row p {
        padding: 0 10px;
        border-right: 2px solid #cccccc;
      }
      .block .footer_links_section .footer_row.copyright p {
        border: 0;
        padding: 0;
      }
      .block .footer_links_section .footer_row p.cart_link,
      .block .footer_links_section .footer_row p.contact_us,
      .block .footer_links_section .footer_row p.customer_info,
      .block .footer_links_section .footer_row.privacy p:last-child {
        border-right: 0;
      }
      .block .footer_links_section .footer_row p.customer_info a {
        color: #000000;
        font-size: 12px;
        font-weight: bold;
      }
      .block .sns_icons_section .snsheader {
        color: #000000;
        font-size: 12px;
      }
    }
  }
  .product-content-grid-v1 li {
    min-height: 490px;
    margin-bottom: 15px;
  }
}
ul.spp_view {
  .spp-product {
    .shaded {
      .button-wrapper {
        float: left;
        width: 48%;
        display: inline-block;
        .add-to-cart {
          line-height: 37px;
        }
      }
      .button-wrapper:nth-child(2) {
        float: right;
        .add-to-cart {
          background-color: #ffffff;
          color: #5dba98;
          border: 1px solid #5dba98;
          line-height: 37px;
        }
      }
      .spp_product_status {
        .coming_soon_stock_msg {
          float: left;
          width: 100%;
          a {
            float: left;
            width: 48%;
            line-height: 37px;
          }
          a.btn-email {
            float: right;
            clear: right;
          }
        }
      }
    }
  }
  .product-info {
    .mobile_spp_review_chat {
      border-bottom: 1px solid #dcdcdc;
      padding-bottom: 15px;
      margin-bottom: 10px;
      .reviews,
      .we-chat {
        text-align: center;
      }
      .reviews {
        border-bottom: none;
        padding-bottom: 0px;
        margin-bottom: 0px;
        .BVRRSocialBookmarkingLinks {
          a {
            margin-right: 0;
          }
        }
      }
    }
  }
}
.spp_reviews {
  #BVSubmissionContainer {
    .BVPageBody {
      #BVSectionMediaUploadID {
        #BVFieldPhotoContainerID,
        #BVFieldVideourl_1ContainerID {
          display: none;
        }
      }
    }
  }
}
#top {
  height: auto;
}
