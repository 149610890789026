/* Livechat */

#wo_chatbox {
  display: none;
  @media #{$cr19-large-up} {
    display: initial;
  }
  #wo_online_image {
    z-index: 100;
  }
}

#udesk_btn {
  img {
    max-width: none !important;
  }
}
