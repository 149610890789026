@if $password_strength {
  .password-field {
    &__info {
      @media #{$medium-up} {
        #signin & {
          bottom: 85px;
        }
        #gnav_signin & {
          width: auto;
          bottom: 40px;
          &:before {
            top: 100px;
            #{$rdirection}: 70px;
            -webkit-transform: rotate(270deg);
            -moz-transform: rotate(270deg);
            -o-transform: rotate(270deg);
            transform: rotate(270deg);
          }
        }
        #checkout_signin_new_user & {
          #signin & {
            width: auto;
            bottom: 50px;
          }
        }
      }
    }
    .signin_container & {
      #gnav_signin & {
        overflow: visible;
      }
    }
  }
  .profile-password-update {
    &__rules {
      li {
        color: $color-btn-grey;
        display: flex;
      }
    }
    &.registration-page__content {
      #foreground-node & {
        .profile-password-update__button {
          &.disabled {
            opacity: 0.2;
            cursor: not-allowed;
          }
        }
      }
    }
  }
}
